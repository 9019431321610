@media only screen and (max-width: 600px) {
  footer.wrapper .footer-main .company-info,
  footer.wrapper .footer-main .links {
    height: fit-content;
  }
  footer.wrapper .footer-main .links ul li {
    margin-bottom: 5px;
  }
}
footer.wrapper .social_icons-wrapper ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 0;
  padding: 0;
  list-style: none;
}
@media only screen and (max-width: 600px) {
  footer.wrapper .social_icons-wrapper ul {
    justify-content: center;
  }
}
footer.wrapper .social_icons-wrapper ul li svg {
  width: 30px;
  height: 30px;
}
#slogan {
  font-size: 1.75em;
  line-height: 170px;
  margin: 0;
  float: left;
  padding-top: 25px;
}
#slogan img {
  position: relative;
}
#navigation .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
#navigation .wrapper .navbar-toggle {
  margin: 0;
  padding: 0;
  float: none;
  border: 0;
  border-radius: 0;
}
#navigation .wrapper form.search-form {
  margin: 0;
  width: 12%;
}
#navigation .wrapper form.search-form .form-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: center;
  align-items: stretch;
}
#navigation .wrapper form.search-form .form-wrapper .form-item {
  margin: 0;
  flex: 1 1 0;
}
#navigation .wrapper form.search-form .form-wrapper .form-item input {
  border-radius: 2px 0 0 2px;
  box-shadow: none;
  border: none;
  height: 100%;
  width: 100%;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.8);
}
#navigation .wrapper form.search-form .form-wrapper .form-item input:focus {
  background-color: #fff;
}
#navigation .wrapper form.search-form .form-wrapper .form-submit {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  background: #2567b3;
  padding: 10px 15px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
#navigation .m {
  color: #fff;
}
#navigation .m .ms-toggle:checked ~ ul li.search-toggle {
  background: #2567b3;
}
#navigation .m .ms-toggle:checked ~ ul li.search-wrapper {
  display: block;
}
#navigation .m .navbar-collapse {
  border-top: 0;
}
#navigation .m > ul {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 25%));
}
#navigation .m > ul li {
  display: flex;
  width: 100%;
}
#navigation .m ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#navigation .m ul li {
  background: #0c2248;
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
#navigation .m ul li.search-toggle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
#navigation .m ul li.search-wrapper {
  order: 10;
  grid-column-start: 1;
  grid-column-end: -1;
  display: none;
}
#navigation .m ul li.search-wrapper .search-form {
  margin: 0;
}
#navigation .m ul li.search-wrapper .search-form .container-inline {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 2.5vw 5vw;
}
#navigation .m ul li.search-wrapper .search-form .container-inline .form-item {
  margin: 0;
  flex: 1 1 0;
}
#navigation .m ul li.search-wrapper .search-form .container-inline .form-item label {
  display: none;
}
#navigation .m ul li.search-wrapper .search-form .container-inline .form-item input {
  width: 100%;
  height: 100%;
  border: none;
  padding: 5px;
  color: #333;
}
#navigation .m ul li.search-wrapper .search-form .container-inline .form-item input:active,
#navigation .m ul li.search-wrapper .search-form .container-inline .form-item input:focus {
  outline: none;
}
#navigation .m ul li.search-wrapper .search-form .container-inline button {
  width: fit-content;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 5px;
  padding-right: 5px;
}
#navigation .m ul a {
  color: #fff;
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
}
#navigation .m ul i {
  display: block;
  width: 100%;
  font-size: 2em;
  vertical-align: middle;
}
#navigation .m .dropdown-menu li {
  background-color: #133774;
}
#m-navigation ul li {
  width: 100%;
  float: none;
}
#m-navigation a {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
#block-system-main > #search-form {
  display: none;
}
.webform-client-form .captcha {
  width: 76%;
  margin-left: auto;
  margin-bottom: 15px;
}
@media (max-width: 899px) {
  .webform-client-form .captcha {
    width: 100%;
    margin-left: 0;
  }
}
.front #under4000 .view-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.front #under4000 .view-content > .item {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 0 20px;
  width: calc(100%/2 - 20px);
  float: none;
  padding: 0;
  margin: 0;
}
.front #under4000 .view-content > .item > a {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 3;
}
.front #under4000 .view-content > .item > header,
.front #under4000 .view-content > .item > dl {
  grid-column-start: 2;
}
.front #under4000 .view-content > .item > dl .stock-number,
.front #under4000 .view-content > .item > dl .dt_price,
.front #under4000 .view-content > .item > dl .spec {
  display: none;
}
.front .just-in .view-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.front .just-in .view-content > .van-item {
  width: calc(100%/6 - 20px);
}
@media only screen and (max-width: 600px) {
  .front .price-limited .view-content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .front .price-limited .view-content > .item {
    width: calc(100% - 20px);
    float: none;
    padding: 0;
    margin: 0;
  }
  .front .just-in .view-content > .van-item {
    width: calc(100% - 20px);
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.node-type-van #map {
  margin-top: 20px;
}
.node-type-van #stock_slider.has-video {
  background: #000;
}
.node-type-van #stock .embedded-video {
  --height: 100%;
  position: relative;
  height: var(--height);
}
.node-type-van #stock .embedded-video .svg-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.node-type-van #stock .embedded-video .svg-wrapper .thumb-wrapper {
  background: #fff;
  height: 100%;
  width: 100%;
  display: block;
}
.node-type-van #stock .embedded-video .svg-wrapper .thumb-wrapper img {
  opacity: .5;
}
.node-type-van #stock .embedded-video .svg-wrapper .inner {
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
}
.node-type-van #stock .embedded-video .svg-wrapper .inner .loading {
  width: 100%;
  text-align: center;
}
.node-type-van #stock .embedded-video .svg-wrapper .inner .loading p {
  font-size: 2rem;
  line-height: 1;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 3px;
}
.node-type-van #stock .embedded-video .svg-wrapper .inner svg {
  height: 36px;
  width: 36px;
  animation: rotate 2s linear infinite;
}
.node-type-van #stock .embedded-video .fluid-width-video-wrapper {
  z-index: 2;
}
.node-type-van #stock .embedded-video .fluid-width-video-wrapper iframe {
  display: none;
}
.node-type-van #stock .embedded-video .slick-slide {
  display: none;
}
.node-type-van #stock .embedded-video .slick-slide.slick-current,
.node-type-van #stock .embedded-video .slick-slide.slick-active {
  display: block;
}
.node-type-van #stock .slider-nav .embedded-video {
  position: relative;
  transform: translateY(20%);
}
.node-type-van #stock .slider-nav .embedded-video svg {
  position: absolute;
  top: 50%;
  left: 33%;
  fill: #fff;
  height: 36px;
  width: 36px;
  transform: translate(-50%, -50%);
  stroke: #fff;
  z-index: 1;
}
.node-type-van .floorplan-check:checked ~ .floorplan-button .floorplan-label > span .toggle-off {
  display: block;
}
.node-type-van .floorplan-check:checked ~ .floorplan-button .floorplan-label > span .toggle-on {
  display: none;
}
.node-type-van .floorplan-check:checked ~ .floorplan {
  max-height: 100vh;
}
.node-type-van .floorplan-button {
  float: none;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.node-type-van .floorplan-button .floorplan-label {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 100;
  color: #f09440;
}
.node-type-van .floorplan-button .floorplan-label svg {
  margin-right: 4px;
  stroke: #333;
}
.node-type-van .floorplan-button .floorplan-label > span {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.node-type-van .floorplan-button .floorplan-label > span span {
  margin-right: 4px;
}
.node-type-van .floorplan-button .floorplan-label > span .toggle-off {
  display: none;
}
.node-type-van .floorplan-button .floorplan-label > span .toggle-on {
  display: block;
}
.node-type-van .floorplan {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-out;
}
.node-type-van .floorplan img {
  height: auto;
  display: block;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.view-stock-nodes .view-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
@media (max-width: 1199px) {
  .view-stock-nodes .view-content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 599px) {
  .view-stock-nodes .view-content {
    grid-template-columns: 1fr;
  }
}
#filters-wrapper .navbar .navbar-header .navbar-toggle {
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-family: 'Roboto', 'Arial', 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 400;
  color: #333;
}
#filters-wrapper .navbar .navbar-header .navbar-toggle .title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-end;
}
#filters-wrapper .navbar .navbar-header .navbar-toggle .title span {
  display: none;
}
#filters-wrapper .navbar .navbar-header .navbar-toggle .title:before {
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-family: 'Roboto', 'Arial', 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 400;
  color: #333;
  display: inline-block;
}
#filters-wrapper .navbar .navbar-header .navbar-toggle .title:after {
  display: inline-block;
  margin-left: 5px;
}
#filters-wrapper .navbar .navbar-header .navbar-toggle[aria-expanded=false] .title:before {
  content: attr(data-closed);
}
#filters-wrapper .navbar .navbar-header .navbar-toggle[aria-expanded=false] .title:after {
  height: 2rem;
  width: 2rem;
  content: "";
  mask: url('../../media/feather/filter.svg') no-repeat 100% 100%;
  position: relative;
  mask-size: cover;
  background-color: #333;
}
#filters-wrapper .navbar .navbar-header .navbar-toggle[aria-expanded=true] .title:before {
  content: attr(data-opened);
}
#filters-wrapper .navbar .navbar-header .navbar-toggle[aria-expanded=true] .title:after {
  height: 2rem;
  width: 2rem;
  content: "";
  mask: url('../../media/feather/check.svg') no-repeat 100% 100%;
  position: relative;
  mask-size: cover;
  background-color: #333;
}
#filters-wrapper .navbar .navbar-nav,
#filters-wrapper .navbar .navbar-form {
  opacity: 0;
}
#filters-wrapper .navbar .navbar-nav.is-filtered,
#filters-wrapper .navbar .navbar-form.is-filtered {
  opacity: 1;
}
#filters-wrapper .navbar .navbar-nav.is-filtered .dropdown-toggle,
#filters-wrapper .navbar .navbar-form.is-filtered .dropdown-toggle {
  font-weight: 900;
}
#filters-wrapper .navbar .navbar-nav.active,
#filters-wrapper .navbar .navbar-form.active {
  opacity: 1;
}
#filters-wrapper .navbar .navbar-nav.disabled,
#filters-wrapper .navbar .navbar-form.disabled {
  opacity: .5;
  cursor: not-allowed;
}
#filters-wrapper .navbar .navbar-nav.disabled a,
#filters-wrapper .navbar .navbar-form.disabled a,
#filters-wrapper .navbar .navbar-nav.disabled select,
#filters-wrapper .navbar .navbar-form.disabled select {
  pointer-events: none;
  cursor: not-allowed;
}
#filters-wrapper .navbar .navbar-nav .view-content .form-group,
#filters-wrapper .navbar .navbar-form .view-content .form-group {
  margin-bottom: 10px;
}
